import { createMuiTheme } from "@material-ui/core/styles";
var theme = createMuiTheme({
  palette: {
    primary: {
      light: "#A7AAb3",
      main: "#222A41"
    },
    secondary: {
      main: "#E87B70"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
});
export default theme;
export var fontFamilyMincho = 'source-han-serif-japanese, serif, "Sawarabi Mincho"';