export var menuItems = [{
  label: "M-STYLEについて",
  to: "/about/m-style"
}, {
  label: "M’s-Realty",
  to: "/about/ms-realty"
}, {
  label: "M’s-Design",
  to: "/about/ms-design"
}, {
  label: "採用情報",
  to: "/recruit"
}, {
  label: "会社概要",
  to: "/company"
}, {
  label: "お問い合わせ",
  to: "/contact"
}];